import React from "react";
import { Route, Switch  } from 'react-router-dom';
import MainTemplate from "./MainTemplate";
import Dashboard from "../views/Dashboard";
import Contact from "../views/Contact";

import About from "../views/About";
import Reference from "../views/Reference";
import Certified from "../views/Certified";
import Gallery from "../views/Gallery";





export default class Template extends React.Component {

   render() {
      return (
         <MainTemplate {...this.props}>
            <Switch>
               <Route exact path="/contatti" component={Contact} />
               <Route exact path="/chi-siamo" component={About} />
               <Route exact path="/referenze" component={Reference} />
               <Route exact path="/certificati" component={Certified}/>
               <Route exact path="/gallery" component={Gallery}/>

               <Route exact path="/" component={Dashboard} />


            </Switch>
         </MainTemplate>
      );
   }
}

