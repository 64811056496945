import React, {Component} from 'react';

import '../../css/views/reference.css'

import mida from '../../img/mida.png'
import sovreco from '../../img/sovreco.png'
import mosmode from '../../img/mosmode.png'
import sasol from '../../img/sasol.png'

class Reference extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="reference">
            <div className="reference-block">
               <h2 className="reference-title">
                  Aziende leader nel settore della gestione dei rifiuti speciali pericolosi e non
               </h2>
               <div className="reference-images">

                  <img className="reference-img" src={mida} alt="Mida srl" title="Mida srl"/>
                  <img className="reference-img" src={sovreco} alt="Sovreco spa" title="Sovreco spa"/>
                  <img className="reference-img" src={mosmode} alt="Mosmode sas" title="Mosmode sas"/>

               </div>
            </div>
            <div className="reference-block">
               <h2 className="reference-title">
                  Aziende leader nel settore della produzione di prodotti chimici
               </h2>
               <div className="reference-images">

                  <img className="reference-img" src={sasol} alt="Sasol Italy spa" title="Sasol Italy spa"/>


               </div>
            </div>
      
         </div> 
        );
    }
}

export default Reference;