import React, { Component } from 'react';

import '../../css/views/gallery.css';


//  https://www.w3schools.com/howto/howto_css_modals.asp
class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div id="gallery">
                <div className="gallery-title-box">
                    <h2 className="gallery-title">Galleria</h2>
                </div>
                <div className="gallery-content-box">
                    <h4 className="gallery-content">La pagina al momento è in costruzione</h4>
                    <div className="progress">
                        <div className="progress-bar-filling"></div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Gallery;