import React, { Component } from 'react';
import '../../css/template_components/footer.css';

import certificazioni from '../../img/certificazioni.png';
import title_img from '../../img/scrittaGV.png';

import {
    PhoneOutlined,MailOutlined
  } from '@ant-design/icons';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="footer">

                <div className="footer-block-container">
                    <div className="footer-block">
                    <h5>GV TRASPORTI SRL</h5>
                        <hr className="footer-hr"/>
                        Via del Timo, 5 - 88900 Crotone (KR)<br/>
                        <PhoneOutlined/>  Tel: +39 0962 930670<br/><br/>
                        P.IVA 03142700792<br/><br/>
                        <MailOutlined/>  gvtrasporti@tiscali.it
                    </div>
                    
                </div>
                <div className="footer-row">
                    <div className="footer-col-sx">
                        <img className="footer-img-big" src={title_img} alt="" />
                    </div>
                    <div className="footer-col-dx">

                        <img className="footer-img" src={certificazioni} alt="" />
                    </div>
                </div>
                <div className="inner-footer">
                 © 2019 GV Trasporti srl.
                </div>
            </div>
        );
    }
}

export default Footer;