import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Template from './components/template/Template';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Template />
      </BrowserRouter>
    </div>
  );
}

export default App;
