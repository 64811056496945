import React from "react";
//import '../../../css/mainLayout/header/header.css';
import '../../css/template_components/header.css';


import title_img from '../../img/scrittaGV.png';

export default class Header extends React.Component{ 
   
   
   render(){ 
      return( 
         <div id="header">
             
             <div className="header-title">
                <h1 className="header-title-element">
                   <img className="header-img" src={title_img} alt=""/>
                </h1>
             </div>
             
         </div> 
      ); 
   }
}
