import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import '../../css/views/certified.css';



const list = [
    { id: 1, nome: "Aumento a Classe C - Cat.4", download: "/files/aumento_a_classe_c_cat4.pdf" },
    { id: 2, nome: "Aumento a Classe C - Cat.5", download: "/files/aumento_a_classe_c_cat5.pdf" },
    { id: 3, nome: "ISCRIZIONE ALBO GESTORI AMBIENTALI - Cat. 4 E", download: "/files/iscr_albo_gestori_ambientali_cat4_e.pdf" },
    { id: 4, nome: "ISCRIZIONE ALBO GESTORI AMBIENTALI - Cat. 5 D", download: "/files/iscr_albo_gestori_ambientali_cat5_d.pdf" },
    { id: 5, nome: "Certificazione ambientale ISO 14001-2015 ITA", download: "/files/certificazione_ambientale_ISO_14001-2015_ITA.pdf" },
    { id: 6, nome: "Certificazione qualità ISO 9001-2015 ITA", download: "/files/certificazione_qualità_ISO_9001-2015_ITA.pdf" }];


class Certified extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const lista = list.map((element) => <tr key={element.id}>
            <td>{element.nome}</td>
            <td>(<Link to={element.download} target="_blank" download>download</Link>)</td>

        </tr>);
        return (
            <div className="certified">
                <div className="certified-title-box">
                    <h2 className="certified-title">Certificati</h2>
                </div>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th colspan="2">Certificati</th>
                        </tr>

                    </thead>
                    <tbody>
                        {lista}
                    </tbody>
                </Table>

            </div>

        );
    }
}

export default Certified;