import React, { Component } from 'react';

import {
    PhoneOutlined, EnvironmentOutlined
} from '@ant-design/icons';

import side_img from '../../img/logo.png';
import '../../css/views/contact.css';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div id="contact">
                <div className="contact-title-box">
                    <h2 className="contact-title">Contatti</h2>
                </div>

                <div className="contact-box">
                    <div className="contact-inner-box-sx">
                        <img className="contact-img" src={side_img} alt="" />
                    </div>

                    <div className="contact-inner-box-dx">
                        
                    <div className="contact-pseudo" />

                        <div className="contact-info">
                            <div className="contact-line">
                                <p><EnvironmentOutlined />  Via del Timo, 5 - 88900 Crotone (KR)</p>
                            </div>
                            <div className="contact-line">
                                <p><PhoneOutlined />  Tel: +39 0962 930670</p>
                            </div>
                            <br/>
                            <div className="contact-line"><h5>Per qualsiasi informazione contattaci alle seguenti mail:</h5></div>
                            <div className="contact-line-bordered">
                                <h6>Email</h6>

                                <p>gvtrasporti@tiscali.it</p>
                            </div>
                            <div className="contact-line-bordered">
                                <h6>PEC</h6>

                                <p> gvtrasporti@arubapec.it</p>
                            </div>
                        </div>
                        <div className="contact-pseudo" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;