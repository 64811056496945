import React, { Component } from 'react';

import '../../css/views/about.css';

import team from '../../img/team.jpg';
import servizi_img from '../../img/servizi.jpg';
import autoarticolati from '../../img/parco_automezzi.jpg';

const introduzione = <div className="about-first-p">
    <p >La GV Trasport Srl, opera nel settore trasporti da circa mezzo secolo, su tutto il territorio nazionale e
    internazionale, avendo avviato un processo di sviluppo in grado di garantire la presenza nelle più importanti
                           e strategiche posizioni geografiche</p>
</div>;

const personale = <div className="about-right">
    <h2 className="about-title">Il personale</h2>
    <p>Tutte le figure professionali aziendali, indipendentemente dalle specifiche responsabilità assegnate,
    sono determinanti per il raggiungimento degli obiettivi: qualit&aacute;, tutela dell'ambiente, salute e sicurezza
    dei lavoratori. L'applicazione del Sistema di Gestione Integrato coinvolge, pertanto, tutte le funzioni e
    richiede la partecipazione, l'impegno e l'efficace interazione di tutto il personale dell'organizzazione
    verso cui vengono attuate politiche di coinvolgimento.
                        </p>
    <p >Il personale della GV Trasporti Srl &egrave; altamente qualificato e specializzato.<br />
                        Grande attenzione &egrave; dedicata ai corsi di formazione che i dipendenti e i collaboratori seguono al fine di elevare il
                        proprio livello professionale.<br /><br />

    </p>
</div>;

const servizi = <div className="about-left">
    <h2 className="about-title">Servizi</h2>
    <p>L'azienda abbraccia molteplici servizi di trasporto:</p>
    <ul>
        <li>Trasporto di merce alla rinfusa con automezzi ribaltabili e vasche;</li>
        <li>Trasporto di merce palettizzata con automezzii cassonati;</li>
        <li>Trasporto di merce liquida con autocisterne;</li>
        <li>Trasporto di merce pericolosa in regime ADR;</li>
        <li>Trasporto di rifiuti pericolosi e non.</li>
    </ul>
    <p>
        Anche nel settore alimentare la nostra societ&aacute; &egrave; in grado di garantire un trasporto full-control dai siti di
                        di produzione alle piattaforme europee di distribuzione.<br /><br />
                        L'azienda è iscritta all'albo gestori ambientali con il N° CZ03494 per la categoria 4 classe C (trasporto di rifiuti
                        speciali non pericolosi) e per la categoria 5 classe C (trasporti di rifiuti speciali pericolosi).
                     </p>
</div>;

const automezzi = <div className="about-right">
    <h2 className="about-title">Automezzi</h2>
    <p>Il nostro parco automezzi è composto da diverse tipologie di autoarticolati:</p>
    <ul>
        <li>semirimrochi centinati;</li>
        <li>semirimorchi ribaltabili posteriori;</li>
        <li>semirimorchi ribaltabili laterali;</li>
        <li>semirimorchi cisterne per trasporto liquido;</li>
        <li>semirimorchi trasporto rottami di ferro;</li>
        <li>semirimorchi walking floor.</li>

    </ul>
    <p>L'azienda dispone di un'area di oltre 15.000 metri quadrati, all'interno della zona industriale di Crotone, completamente
    attrezzata per svolgere tutti i servizi logistici necessari, dal deposito e stoccaggio merci, alla sosta degli automezzi
    custoditi.
                        </p>
</div>;

const other = <div className="about-first-p">
    <p>
        La soddisfazione del cliente &egrave; la condizione indispensabile per il successo e l'accrescimento della nostra azienda.
                     </p>
</div>


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="about">
                <div className="about-title-box">
                    <h2 className="about-title">Chi siamo</h2>
                </div>
                <div className="about-center-box">
                    {introduzione}
                </div>

                <div className="about-box">
                    {personale}
                    <div className="about-img-right"><img src={team} className="about-img" alt="img" /></div>


                </div>

                <div className="about-box">
                    {servizi}
                    <img src={servizi_img} className="about-img-left" alt="img" />

                </div>

                <div className="about-box">
                    {automezzi}
                    <img src={autoarticolati} className="about-img-right" alt="img" />

                </div>

                <div className="about-center-box">
                    {other}
                </div>

            </div>

        );
    }
}

export default About;