import React from "react";
import Footer from "../template_components/Footer";
import Menubar from "../template_components/Menubar";
import HeaderFixed from "../template_components/HeaderFixed";
import Header from "../template_components/Header";
import ScrollToTop from "../template_components/ScrollToTop";


export default class MainTemplate extends React.Component {


    constructor(props) {
        super(props);
        this.state = { id: "header-fixed-none" };
        this.handleScroll = this.handleScroll.bind(this);
    }


    handleScroll() {
        if (window.scrollY > 120) {
            this.setState({ id: "header-fixed" })
        }
        else {
            this.setState({ id: "header-fixed-none" })

        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

    }
    render() {
        return (
            <div className="super-container">
                <ScrollToTop />
                <Header />
                <HeaderFixed id={this.state.id} />
                <Menubar />
                <>
                    {this.props.children}
                </>
                <Footer />
            </div>
        );
    }
}

