import React, { useState } from "react";
import { Nav, Navbar } from 'react-bootstrap';

import '../../css/template_components/menubar.css';


import logo from '../../img/logo2.png';
import { Link } from "react-router-dom";



/*class Menubar extends React.Component {

    const [expanded, setExpanded] = useState(false);


    render() {
        console.log(this.state)

        return (
            <Navbar expanded={expanded} className="menubar" expand="lg">
                <Navbar.Brand><img className="navBar-img" width="50px" src={logo} alt="" /></Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav >
                        <Link to="/" onClick={() => setExpanded(false)}><label className="menubar-element">Home</label></Link>
                        <Nav.Link href="/chi-siamo"><label className="menubar-element">Chi siamo</label></Nav.Link>
                        <Nav.Link href="/certificati"><label className="menubar-element">Certificati</label></Nav.Link>
                        <Nav.Link href="/referenze"><label className="menubar-element">Referenze</label></Nav.Link>
                        <Nav.Link href="/gallery"><label className="menubar-element">Gallery</label></Nav.Link>
                        <Nav.Link href="/contatti"><label className="menubar-element">Contatti</label></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
*/
function Menubar(){
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar expanded={expanded} className="menubar" expand="lg">
            <Navbar.Brand><img className="navBar-img" width="50px" src={logo} alt="" /></Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <Nav >
                    <Link to="/" onClick={() => setExpanded(false)}><label className="menubar-element">Home</label></Link>
                    <Link to="/chi-siamo" onClick={() => setExpanded(false)}><label className="menubar-element">Chi siamo</label></Link>
                    <Link to="/certificati" onClick={() => setExpanded(false)}><label className="menubar-element">Certificati</label></Link>
                    <Link to="/referenze" onClick={() => setExpanded(false)}><label className="menubar-element">Referenze</label></Link>
                    <Link to="/gallery" onClick={() => setExpanded(false)}><label className="menubar-element">Gallery</label></Link>
                    <Link to="/contatti" onClick={() => setExpanded(false)}><label className="menubar-element">Contatti</label></Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Menubar;
/**
 *
 *
 * <Nav.Link href="/contatti"><label className="menubar-element">Contatti</label></Nav.Link>
 */