import React, { Component } from 'react';

import '../../css/views/dashboard.css';

import slide1 from '../../img/contatti_sfondo.jpg';
import slide2 from '../../img/slide_servizi.jpg';
import slide3 from '../../img/slide_automezzi.jpg';

import card1 from '../../img/infinite3.png';
import card2 from '../../img/light2.png';
import card3 from '../../img/check2.png';


import { Carousel, Card } from 'react-bootstrap';

const listItems = [
    { title: 'step 1', content: 'Personale altamente qualificato e specializzato', img: slide1 },
    { title: 'step 2', content: 'Offriamo un servizio full-control per ogni tipo di merce', img: slide2 },
    { title: 'step 3', content: 'Un eccellente gamma di automezzi per soddisfare le esigenze del nostro cliente', img: slide3 }
];

const cardList = [
    { content: 'Da mezzo secolo nel settore', img: card1 },
    { content: 'Soluzioni personalizzate per ogni esigenza', img: card2 },
    { content: 'La nostra esperienza al vostro servizio', img: card3 },

];



class Dashboard extends Component {

    render() {
        const lista = listItems.map((element) => <Carousel.Item key={element.title}>
            <div className="carousel-img-container">
                <img
                    className="carousel-img"
                    src={element.img}
                    alt="slide"
                />
            </div>

            <Carousel.Caption >
                <p className="carousel-section">{element.content}</p>
            </Carousel.Caption>

        </Carousel.Item>);
        const cards = cardList.map((element) => <Card className="dashboard-card" key={element.content}>
            <Card.Img className="dashboar-img" src={element.img} />
            <Card.Body>
                <Card.Text>{element.content}</Card.Text>
            </Card.Body>
        </Card>)
        return (
            <div className="dashboard">
                <Carousel indicators={false}>
                    {lista}
                </Carousel>
                <div className="dashboard-color-line"></div>
                <div className="parallax"></div>
                <div className="dashboard-line">
                    <div className="dashboard-inner-line">
                        {cards}
                    </div>

                </div>
                <div className="parallax"></div>

            </div>

        );
    }
}

export default Dashboard;


/**
 * <div className="dashboard-line">
                    <div className="dashboard-inner-line">
                        {cards}
                    </div>

                </div>
 */